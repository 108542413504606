<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('client.detail')"
    class="px-5 py-3"
    v-if="detail"
  >
    <v-col cols="12" class="text-right add--button">
      <router-link :to="`${basePathClient}/${detail.uuid}`">
        <v-btn color="success" class="bottom--button mr-0">
          {{ $t('client.btn.viewDetail') }}
        </v-btn>
      </router-link>
    </v-col>
    <v-row>
      <v-col cols="6">
        {{ $t('client.uuid') }}
      </v-col>
      <v-col cols="6">
        {{ detail.uuid }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('client.name') }}
      </v-col>
      <v-col cols="6">
        {{ detail.name }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('client.phone') }}
      </v-col>
      <v-col cols="6">
        {{ detail.phone_number }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('client.email') }}
      </v-col>
      <v-col cols="6">
        {{ detail.email }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('client.notes') }}
      </v-col>
      <v-col cols="6">
        {{ detail.notes }}
      </v-col>
    </v-row>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';

export default {
  mixins: [HelperMixin],
  props: ['detail', 'permissions', 'allowedActions'],
  data() {
    return {
      format,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.transaction.basePath,
      basePathClient: (state) => state.client.basePath,
    }),
  },
  methods: {},
};
</script>
